/**
 * Created by Niels Lam on 15-Aug-17.
 */
(function($) {
    $('.d-image').each(function(){
        var img1 = $(this).find('.FlexEmbed-content').data('img-1');
        var img2 = $(this).find('.FlexEmbed-content').data('img-2');
        var d = new Date();
        var h = d.getHours();
        var imgUrl = img1;
        if(h > 6 && h < 18) {
            imgUrl = img2;
        }
        $(this).find('.FlexEmbed-content').css('background-image', 'url(' + imgUrl + ')');
        console.log(imgUrl);
    });
})(jQuery);